<template>
  <div class="DailySummaryList-wrapper">
    <list ref="list"
          v-if="canInit"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="新增指标"
                  permission="add"
                  @click="create()"></v-button>
      </template>
      <template #searchSlot>
        <v-input v-model="searchParams.name"
                 label="指标名称"></v-input>
        <v-select label="指标类型"
                  v-model="searchParams.categoryCode"
                  :options="categoryOps"
                  @change="categoryCodeChange"></v-select>
        <v-select label="统计口径"
                  v-model="searchParams.caliberCode"
                  :options="caliberCodeOps"></v-select>
        <v-select2 ref="communityIdSelect2"
                   label="考核对象"
                   placeholder="查询项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"></v-select2>
        <v-select label="指标周期"
                  v-model="searchParams.period"
                  :options="periodOps"></v-select>
        <v-datepicker label="指标年份"
                      type="year"
                      v-model="searchParams.evaluateYear"
                      :maxDate="maxDate"></v-datepicker>
        <v-input v-model="searchParams.inuserName"
                 label="创建人"></v-input>
        <v-datepicker label="创建时间段"
                      type="rangedatetimer"
                      :maxDate="new Date()"
                      :startTime.sync="searchParams.startDate"
                      :endTime.sync="searchParams.endDate" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  class="editBtn"
                  permission="update"
                  @click="edit(scope.row)"></v-button>
        <v-button text="删除"
                  type="text"
                  class="deleteBtn"
                  permission="delete"
                  @click="deleteItem(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getCategoryListURL, getCaliberListURL, deletedURL } from './api'
import { periodOps } from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'kpiManagementList',
  data () {
    return {
      maxDate: new Date(),
      canInit: false,
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        name: '',
        categoryCode: undefined,
        caliberCode: undefined,
        period: undefined,
        evaluateYear: (new Date().getFullYear()) + '',
        inuserName: '',
        startDate: '',
        endDate: ''
      },
      periodOps: periodOps(1),
      communityParams,
      categoryOps: [],
      caliberCodeOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      headers: [
        {
          prop: 'name',
          label: '指标名称'
        },
        {
          prop: 'categoryName',
          label: '指标类型'
        },
        {
          prop: 'caliberName',
          label: '统计口径'
        },
        {
          prop: 'indicator',
          label: '指标数值'
        },
        {
          prop: 'communityNames',
          label: '考核对象'
        },
        {
          prop: 'period',
          label: '指标周期'
        },
        {
          prop: 'evaluateYear',
          label: '指标年份'
        },
        {
          prop: 'inuserName',
          label: '创建人'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  created () {
    this.getCategory()
  },
  methods: {
    categoryCodeChange (data, item) {
      this.searchParams.caliberCode = undefined
      this.caliberCodeOps = [
        {
          text: '全部',
          value: undefined
        }
      ]
      data && this.getcaliber(item)
    },
    getCategory () {
      this.$axios.get(getCategoryListURL, {}).then(res => {
        if (res.status === 100) {
          let data = res.data || []
          let list = [
            {
              text: '全部',
              value: undefined
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.categoryName,
              value: v.categoryCode,
              categoryId: v.categoryId
            })
          })
          this.categoryOps = list
        }
        this.canInit = true
      })
    },
    getcaliber (item) {
      this.$axios.get(getCaliberListURL, {
        params: {
          categoryId: item.categoryId
        }
      }).then(res => {
        if (res.status === 100) {
          let data = res.data || []
          let list = [
            {
              text: '全部',
              value: undefined
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.caliberName,
              value: v.caliberCode
            })
          })
          this.caliberCodeOps = list
        }
      })
    },
    edit (row) {
      this.$router.push({
        name: 'kpiManagementForm',
        query: {
          indicatorId: row.id
        }
      })
    },
    deleteItem (row) {
      let _this = this
      _this.$axios.delete(deletedURL, {
        params: {
          indicatorId: row.id
        }
      }).then(res => {
        let returnStatus = String(res.status)
        if (returnStatus === '100') {
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
          _this.$refs.list.searchData()
        }
      })
    },
    create () {
      this.$router.push({
        name: 'kpiManagementForm'
      })
    }
  }
}
</script>
