// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}indicator/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}indicator/export`
// 指标类型
const getCategoryListURL = `${API_CONFIG.butlerBaseURL}indicator/category`
// 统计口径
const getCaliberListURL = `${API_CONFIG.butlerBaseURL}indicator/caliber`
// 指标新增
const submitCreateFormURL = `${API_CONFIG.butlerBaseURL}indicator/add`
// multi-select 项目列表
const getCommunityListURL = `${API_CONFIG.baseURL}serverCommunityAction!getCommunityList.action`

// select2 运营项目组
const getOperateRegionListURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 项目阶段状态
const getCommunityStageURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getCommunityTypeURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`

// 考核对象
const deletedURL = `${API_CONFIG.butlerBaseURL}indicator/delete`
// 获取详情接口
const queryUrl = `${API_CONFIG.butlerBaseURL}indicator/detail`
// 编辑详情接口
const updateUrl = `${API_CONFIG.butlerBaseURL}indicator/update`

export {
  getListURL,
  exportListURL,
  getCategoryListURL,
  submitCreateFormURL,
  getCommunityListURL,
  getOperateRegionListURL,
  getCommunityStageURL,
  getCommunityTypeURL,
  getCaliberListURL,
  deletedURL,
  queryUrl,
  updateUrl
}
