import { mapHelper } from 'common/utils'

// 季度
const period = [
  {
    text: '每年',
    value: '2'
  }, {
    text: '每季',
    value: '3'
  }, {
    text: '每月',
    value: '1'
  }
]
// 项目状态
const communityStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  },
  {
    text: '作废',
    value: 2
  }
]
// 区域类型
const searchRegionType = [
  {
    text: '分子公司',
    value: 0
  },
  {
    text: '园区公司分公司',
    value: 1
  },
  {
    text: '管理项目组',
    value: 2
  }
]
const { map: periodMap, setOps: periodOps } = mapHelper.setMap(period)
const { map: communityStatusMap, setOps: communityStatusOps } = mapHelper.setMap(communityStatus)
const { map: searchRegionTypeMap, setOps: searchRegionTypeOps } = mapHelper.setMap(searchRegionType)
export { periodMap, periodOps, communityStatusMap, communityStatusOps, searchRegionTypeMap, searchRegionTypeOps }
